/*
 * @Author: fanzh fanzh@leadingcloud.xyz
 * @Date: 2022-09-25 21:45:12
 * @LastEditors: fanzh fanzh@leadingcloud.xyz
 * @LastEditTime: 2022-10-20 11:03:46
 * @FilePath: /ldc-fe-site-xiaoke/src/pages/404.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as React from "react"

import Seo from "../components/seo"

const NotFoundPage = () => (
 <>
 <Seo title="404: Not found" />
    <div className="container text-center my-5">
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
 </>
)

export default NotFoundPage
